body,
html {
  font-family: "Hurme Geometric Sans 3";
}

@font-face {
  font-family: "Hurme Geometric Sans 3";
  src: url("./fonts/HurmeGeometricSans3-Regular.eot");
  src: local("HurmeGeometricSans3 Regular"),
    local("HurmeGeometricSans3-Regular"),
    url("./fonts/HurmeGeometricSans3-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/HurmeGeometricSans3-Regular.woff2") format("woff2"),
    url("./fonts/HurmeGeometricSans3-Regular.woff") format("woff"),
    url("./fonts/HurmeGeometricSans3-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
